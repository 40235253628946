import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
//import { graphql } from "gatsby";
//import { StaticImage } from "gatsby-plugin-image"
import ComponentTextblock1 from "../components/component-textblock-1";
import { useIntl } from "gatsby-plugin-react-intl";
import moment from 'moment-timezone/builds/moment-timezone-with-data';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

const PageContact = ({ data, location }) => {
  const intl = useIntl();
  const [value, setValue] = React.useState({country: "Germany"});
  const [serverResponse, setServerResponse] = React.useState("");
  //const moment = require("moment");

  function handleChange(e) {
    value[e.target.name] = e.target.value;
    setServerResponse("");
    setValue({ ...value });
  }

  const portalId = "25946107"
  const formGuid = "a23f47a0-9b90-4628-91f3-14fbe6376181"

  const datetime = moment().tz('Europe/Berlin').format("YYYY-MM-DD HH:mm")

  let payload = {
    "fields":[
      {"objectTypeId": "0-1", "name": "firstname", "value": value.first_name ? value.first_name : "null"},
      {"objectTypeId": "0-1", "name": "lastname", "value": value.last_name ? value.last_name : "null"},
      {"objectTypeId": "0-1", "name": "email", "value": value.email ? value.email : "null"},
      {"objectTypeId": "0-1", "name": "company", "value": value.company ? value.company : "null"},
      {"objectTypeId": "0-1", "name": "reason_of_inquiry", "value": value.reason ? value.reason : "null"},
      {"objectTypeId": "0-1", "name": "website2", "value": value.website ? value.website : "null"},
      {"objectTypeId": "0-1", "name": "zip", "value": value.zip ? value.zip : "null"},
      {"objectTypeId": "0-1", "name": "city", "value": value.city ? value.city : "null"},
      {"objectTypeId": "0-1", "name": "country", "value": value.country ? value.country : "null"},
      {"objectTypeId": "0-1", "name": "state", "value": value.state ? value.state : "null"},
      {"objectTypeId": "0-1", "name": "which_best_describes_you_", "value": value.describes ? value.describes : "null"},
      {"objectTypeId": "0-1", "name": "submit_date", "value": datetime},
  ]}
  async function onSubmit(e) {
    e.preventDefault();

    //Send data to GTM, GA4
    console.log("Start sending event...")
    if (typeof window !== 'undefined'){
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ event: "formSubmit" });
      console.log("Event sent!")
    }

    fetch('https://api.hsforms.com/submissions/v3/integration/submit/' + portalId + '/' + formGuid, {
      method: 'POST',
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then(response => {
        console.log(response);
      })
      .catch(error => {
        console.error('通信に失敗しました', error);
      });
    const response = await window
      .fetch("/api/form", {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(value),
      })
      .then((res) => res.json());
    setServerResponse(response);
  }

  return (
    <Layout location={location}>
      <Seo
        title={
          intl.locale === "en"
            ? `Contact us - How to contact Cyberdyne`
            : `Kontaktieren Sie uns - Wie Sie Cyberdyne kontaktieren können`
        }
        description={
          intl.locale === "en"
            ? `Contact Cyberdyne support by email or phone. Get in touch with us! Available from 8:30am-17:00pm.`
            : `Kontaktieren Sie Cyberdyne per E-Mail oder Telefon. Treten Sie in Kontakt mit uns, wir sind von 8:30-17:30 Uhr erreichbar.`
        }
        ogImage="https://images.ctfassets.net/t2ifj85iosf3/Gx3Sc0gyL2KygjlYoItex/6e87f29d8f2062cd9c58ba8cbb82fbfc/E169.jpg?w=1200&h=630&fit=fill"
      />
      <section className="sect">
        <div className="container">
          <ComponentTextblock1
            title={
              intl.locale === "en" ? `Get in Touch` : `Kontaktieren Sie uns`
            }
            subtitle={intl.locale === "en" ? `Inquiry` : `Anfrage`}
            content={
              intl.locale === "en"
                ? `Thank you for your interest in Cyberdyne. If you can’t find the information you need on our website, please email us using the form below. Alternatively, you can call us on: +49 0234 5873007008, Monday to Friday from 8.30am-5pm`
                : `Vielen Dank für Ihr Interesse an Cyberdyne. Sollten Sie die gesuchten Informationen nicht auf unserer Internetseite finden, nutzen Sie gerne das untenstehende Formular, um uns eine E-Mail zu schreiben. Montags - Freitags von 8.30 - 17.00 Uhr können Sie uns gerne auch telefonisch unter folgender Nummer anrufen: +49 0234 5873007008 `
            }
          />
        </div>
      </section>
      <section className="sect sect_Product__catch4 sect_Product__catch5 p-0 gradient">
        <div className="sect_Product__catch___2">
          <div className="">
            <div className="container">
              <div className="container-bg-right">
                <h3 className="inquiry_title mb-8">
                  {intl.locale === "en" ? `Inquiry Form` : `Kontaktfomular`}
                </h3>
                <h3 className="h3">
                  {intl.locale === "en"
                    ? `Let us know about you:`
                    : `Lassen Sie uns mehr über Sie und Ihr Anliegen wissen`}
                </h3>
                <p className="mb-8">
                  {intl.locale === "en"
                    ? `Please fill in all the fields that are indicated as mandatory.`
                    : `Bitte füllen Sie alle gekennzeichneten Pflichtfelder aus`}
                </p>

                <GoogleReCaptchaProvider reCaptchaKey="6LeUuUMjAAAAALHX3rv0pOAQrP4VBxOZXnFTtDKf">
                <form
                  onSubmit={onSubmit}
                  method="POST"
                  action="/api/form"
                  className="formWrapper"
                >
                  <div className="flex-row mx-n2">
                    <div className="flex-column-6 px-2">
                      <div className="mb-4">
                        <h3 className="mb-2">
                          {intl.locale === "en" ? `First Name ` : `Vorname `}*
                        </h3>
                        <input
                          type="text"
                          name="first_name"
                          className="form-control"
                          value={value["first_name"] || ``}
                          onChange={handleChange}
                        />

                        {serverResponse.indexOf("null_first_name") >= 0
                          && <div className="form-alert-box"><span>{intl.locale === "en" ? `Fill out this field` : `Bitte füllen Sie das Feld aus.`}</span></div>
                        }
                      </div>

                      <div className="mb-4">
                        <h3 className="mb-2">
                          {intl.locale === "en" ? `Last Name ` : `Nachname `}*
                        </h3>
                        <input
                          type="text"
                          name="last_name"
                          className="form-control"
                          value={value["last_name"] || ``}
                          onChange={handleChange}
                        />

                        {serverResponse.indexOf("null_last_name") >= 0
                          && <div className="form-alert-box"><span>{intl.locale === "en" ? `Fill out this field` : `Bitte füllen Sie das Feld aus.`}</span></div>
                        }
                      </div>

                      <div className="mb-4">
                        <h3 className="mb-2">
                          {intl.locale === "en" ? `Email ` : `E-Mail `}*
                        </h3>
                        <input
                          type="email"
                          name="email"
                          className="form-control"
                          value={value["email"] || ``}
                          onChange={handleChange}
                        />

                        {serverResponse.indexOf("null_email") >= 0
                          && <div className="form-alert-box"><span>{intl.locale === "en" ? `Fill out this field` : `Bitte füllen Sie das Feld aus.`}</span></div>
                        }
                      </div>

                      <div className="mb-4">
                        <h3 className="mb-2">
                          {intl.locale === "en" ? `Company name` : `Firma`}*
                        </h3>
                        <input
                          type="text"
                          name="company"
                          className="form-control"
                          value={value["company"] || ``}
                          onChange={handleChange}
                        />

                        {serverResponse.indexOf("null_company") >= 0
                          && <div className="form-alert-box"><span>{intl.locale === "en" ? `If not applicable, note as N/A` : `Wenn nicht zutreffend, N/A eintragen.`}</span></div>
                        }
                      </div>

                      <div className="mb-4">
                        <h3 className="mb-2">
                          {intl.locale === "en" ? `Website` : `Webseite`}*
                        </h3>
                        <input
                          type="text"
                          name="website"
                          className="form-control"
                          onChange={handleChange}
                        />

                        {serverResponse.indexOf("null_website") >= 0
                          && <div className="form-alert-box"><span>{intl.locale === "en" ? `If not applicable, note as N/A` : `Wenn nicht zutreffend, N/A eintragen.`}</span></div>
                        }
                      </div>

                      <div className="mb-4">
                        <h3 className="mb-2">
                          {intl.locale === "en"
                            ? `Reason of inquiry `
                            : `Grund der Anfrage `}
                          *
                        </h3>
                        <textarea
                          rows="5"
                          name="reason"
                          className="form-control"
                          value={value["reason"] || ``}
                          onChange={handleChange}
                        />

                        {serverResponse.indexOf("null_reason") >= 0
                          && <div className="form-alert-box"><span>{intl.locale === "en" ? `Fill out this field` : `Bitte füllen Sie das Feld aus.`}</span></div>
                        }
                      </div>

                    </div>

                    <div className="flex-column-6 px-2">
                      <h3 className="mb-2">
                        {intl.locale === "en" ? `Country ` : `Land `}*
                      </h3>
                      {/*}
                    <input
                      type="text"
                      name="country"
                      className="form-control mb-4"
                      value={value['country'] || ``}
                      onChange={handleChange}
                      required
                    />
                    */}
                      <select
                        name="country"
                        id="country"
                        className="form-control mb-4"
                        required
                        onChange={handleChange}
                        defaultValue={value["country"] ? value["country"] : "Germany"}
                      >
                        <option value="Afganistan">Afghanistan</option>
                        <option value="Albania">Albania</option>
                        <option value="Algeria">Algeria</option>
                        <option value="American Samoa">American Samoa</option>
                        <option value="Andorra">Andorra</option>
                        <option value="Angola">Angola</option>
                        <option value="Anguilla">Anguilla</option>
                        <option value="Antigua & Barbuda">
                          Antigua & Barbuda
                        </option>
                        <option value="Argentina">Argentina</option>
                        <option value="Armenia">Armenia</option>
                        <option value="Aruba">Aruba</option>
                        <option value="Australia">Australia</option>
                        <option value="Austria">Austria</option>
                        <option value="Azerbaijan">Azerbaijan</option>
                        <option value="Bahamas">Bahamas</option>
                        <option value="Bahrain">Bahrain</option>
                        <option value="Bangladesh">Bangladesh</option>
                        <option value="Barbados">Barbados</option>
                        <option value="Belarus">Belarus</option>
                        <option value="Belgium">Belgium</option>
                        <option value="Belize">Belize</option>
                        <option value="Benin">Benin</option>
                        <option value="Bermuda">Bermuda</option>
                        <option value="Bhutan">Bhutan</option>
                        <option value="Bolivia">Bolivia</option>
                        <option value="Bonaire">Bonaire</option>
                        <option value="Bosnia & Herzegovina">
                          Bosnia & Herzegovina
                        </option>
                        <option value="Botswana">Botswana</option>
                        <option value="Brazil">Brazil</option>
                        <option value="British Indian Ocean Ter">
                          British Indian Ocean Ter
                        </option>
                        <option value="Brunei">Brunei</option>
                        <option value="Bulgaria">Bulgaria</option>
                        <option value="Burkina Faso">Burkina Faso</option>
                        <option value="Burundi">Burundi</option>
                        <option value="Cambodia">Cambodia</option>
                        <option value="Cameroon">Cameroon</option>
                        <option value="Canada">Canada</option>
                        <option value="Canary Islands">Canary Islands</option>
                        <option value="Cape Verde">Cape Verde</option>
                        <option value="Cayman Islands">Cayman Islands</option>
                        <option value="Central African Republic">
                          Central African Republic
                        </option>
                        <option value="Chad">Chad</option>
                        <option value="Channel Islands">Channel Islands</option>
                        <option value="Chile">Chile</option>
                        <option value="China">China</option>
                        <option value="Christmas Island">
                          Christmas Island
                        </option>
                        <option value="Cocos Island">Cocos Island</option>
                        <option value="Colombia">Colombia</option>
                        <option value="Comoros">Comoros</option>
                        <option value="Congo">Congo</option>
                        <option value="Cook Islands">Cook Islands</option>
                        <option value="Costa Rica">Costa Rica</option>
                        <option value="Cote DIvoire">Cote DIvoire</option>
                        <option value="Croatia">Croatia</option>
                        <option value="Cuba">Cuba</option>
                        <option value="Curaco">Curacao</option>
                        <option value="Cyprus">Cyprus</option>
                        <option value="Czech Republic">Czech Republic</option>
                        <option value="Denmark">Denmark</option>
                        <option value="Djibouti">Djibouti</option>
                        <option value="Dominica">Dominica</option>
                        <option value="Dominican Republic">
                          Dominican Republic
                        </option>
                        <option value="East Timor">East Timor</option>
                        <option value="Ecuador">Ecuador</option>
                        <option value="Egypt">Egypt</option>
                        <option value="El Salvador">El Salvador</option>
                        <option value="Equatorial Guinea">
                          Equatorial Guinea
                        </option>
                        <option value="Eritrea">Eritrea</option>
                        <option value="Estonia">Estonia</option>
                        <option value="Ethiopia">Ethiopia</option>
                        <option value="Falkland Islands">
                          Falkland Islands
                        </option>
                        <option value="Faroe Islands">Faroe Islands</option>
                        <option value="Fiji">Fiji</option>
                        <option value="Finland">Finland</option>
                        <option value="France">France</option>
                        <option value="French Guiana">French Guiana</option>
                        <option value="French Polynesia">
                          French Polynesia
                        </option>
                        <option value="French Southern Ter">
                          French Southern Ter
                        </option>
                        <option value="Gabon">Gabon</option>
                        <option value="Gambia">Gambia</option>
                        <option value="Georgia">Georgia</option>
                        <option value="Germany">
                          Germany
                        </option>
                        <option value="Ghana">Ghana</option>
                        <option value="Gibraltar">Gibraltar</option>
                        <option value="Great Britain">Great Britain</option>
                        <option value="Greece">Greece</option>
                        <option value="Greenland">Greenland</option>
                        <option value="Grenada">Grenada</option>
                        <option value="Guadeloupe">Guadeloupe</option>
                        <option value="Guam">Guam</option>
                        <option value="Guatemala">Guatemala</option>
                        <option value="Guinea">Guinea</option>
                        <option value="Guyana">Guyana</option>
                        <option value="Haiti">Haiti</option>
                        <option value="Hawaii">Hawaii</option>
                        <option value="Honduras">Honduras</option>
                        <option value="Hong Kong">Hong Kong</option>
                        <option value="Hungary">Hungary</option>
                        <option value="Iceland">Iceland</option>
                        <option value="Indonesia">Indonesia</option>
                        <option value="India">India</option>
                        <option value="Iran">Iran</option>
                        <option value="Iraq">Iraq</option>
                        <option value="Ireland">Ireland</option>
                        <option value="Isle of Man">Isle of Man</option>
                        <option value="Israel">Israel</option>
                        <option value="Italy">Italy</option>
                        <option value="Jamaica">Jamaica</option>
                        <option value="Japan">Japan</option>
                        <option value="Jordan">Jordan</option>
                        <option value="Kazakhstan">Kazakhstan</option>
                        <option value="Kenya">Kenya</option>
                        <option value="Kiribati">Kiribati</option>
                        <option value="Korea North">Korea North</option>
                        <option value="Korea Sout">Korea South</option>
                        <option value="Kuwait">Kuwait</option>
                        <option value="Kyrgyzstan">Kyrgyzstan</option>
                        <option value="Laos">Laos</option>
                        <option value="Latvia">Latvia</option>
                        <option value="Lebanon">Lebanon</option>
                        <option value="Lesotho">Lesotho</option>
                        <option value="Liberia">Liberia</option>
                        <option value="Libya">Libya</option>
                        <option value="Liechtenstein">Liechtenstein</option>
                        <option value="Lithuania">Lithuania</option>
                        <option value="Luxembourg">Luxembourg</option>
                        <option value="Macau">Macau</option>
                        <option value="Macedonia">Macedonia</option>
                        <option value="Madagascar">Madagascar</option>
                        <option value="Malaysia">Malaysia</option>
                        <option value="Malawi">Malawi</option>
                        <option value="Maldives">Maldives</option>
                        <option value="Mali">Mali</option>
                        <option value="Malta">Malta</option>
                        <option value="Marshall Islands">
                          Marshall Islands
                        </option>
                        <option value="Martinique">Martinique</option>
                        <option value="Mauritania">Mauritania</option>
                        <option value="Mauritius">Mauritius</option>
                        <option value="Mayotte">Mayotte</option>
                        <option value="Mexico">Mexico</option>
                        <option value="Midway Islands">Midway Islands</option>
                        <option value="Moldova">Moldova</option>
                        <option value="Monaco">Monaco</option>
                        <option value="Mongolia">Mongolia</option>
                        <option value="Montserrat">Montserrat</option>
                        <option value="Morocco">Morocco</option>
                        <option value="Mozambique">Mozambique</option>
                        <option value="Myanmar">Myanmar</option>
                        <option value="Nambia">Nambia</option>
                        <option value="Nauru">Nauru</option>
                        <option value="Nepal">Nepal</option>
                        <option value="Netherland Antilles">
                          Netherland Antilles
                        </option>
                        <option value="Netherlands">
                          Netherlands (Holland, Europe)
                        </option>
                        <option value="Nevis">Nevis</option>
                        <option value="New Caledonia">New Caledonia</option>
                        <option value="New Zealand">New Zealand</option>
                        <option value="Nicaragua">Nicaragua</option>
                        <option value="Niger">Niger</option>
                        <option value="Nigeria">Nigeria</option>
                        <option value="Niue">Niue</option>
                        <option value="Norfolk Island">Norfolk Island</option>
                        <option value="Norway">Norway</option>
                        <option value="Oman">Oman</option>
                        <option value="Pakistan">Pakistan</option>
                        <option value="Palau Island">Palau Island</option>
                        <option value="Palestine">Palestine</option>
                        <option value="Panama">Panama</option>
                        <option value="Papua New Guinea">
                          Papua New Guinea
                        </option>
                        <option value="Paraguay">Paraguay</option>
                        <option value="Peru">Peru</option>
                        <option value="Phillipines">Philippines</option>
                        <option value="Pitcairn Island">Pitcairn Island</option>
                        <option value="Poland">Poland</option>
                        <option value="Portugal">Portugal</option>
                        <option value="Puerto Rico">Puerto Rico</option>
                        <option value="Qatar">Qatar</option>
                        <option value="Republic of Montenegro">
                          Republic of Montenegro
                        </option>
                        <option value="Republic of Serbia">
                          Republic of Serbia
                        </option>
                        <option value="Reunion">Reunion</option>
                        <option value="Romania">Romania</option>
                        <option value="Russia">Russia</option>
                        <option value="Rwanda">Rwanda</option>
                        <option value="St Barthelemy">St Barthelemy</option>
                        <option value="St Eustatius">St Eustatius</option>
                        <option value="St Helena">St Helena</option>
                        <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                        <option value="St Lucia">St Lucia</option>
                        <option value="St Maarten">St Maarten</option>
                        <option value="St Pierre & Miquelon">
                          St Pierre & Miquelon
                        </option>
                        <option value="St Vincent & Grenadines">
                          St Vincent & Grenadines
                        </option>
                        <option value="Saipan">Saipan</option>
                        <option value="Samoa">Samoa</option>
                        <option value="Samoa American">Samoa American</option>
                        <option value="San Marino">San Marino</option>
                        <option value="Sao Tome & Principe">
                          Sao Tome & Principe
                        </option>
                        <option value="Saudi Arabia">Saudi Arabia</option>
                        <option value="Senegal">Senegal</option>
                        <option value="Seychelles">Seychelles</option>
                        <option value="Sierra Leone">Sierra Leone</option>
                        <option value="Singapore">Singapore</option>
                        <option value="Slovakia">Slovakia</option>
                        <option value="Slovenia">Slovenia</option>
                        <option value="Solomon Islands">Solomon Islands</option>
                        <option value="Somalia">Somalia</option>
                        <option value="South Africa">South Africa</option>
                        <option value="Spain">Spain</option>
                        <option value="Sri Lanka">Sri Lanka</option>
                        <option value="Sudan">Sudan</option>
                        <option value="Suriname">Suriname</option>
                        <option value="Swaziland">Swaziland</option>
                        <option value="Sweden">Sweden</option>
                        <option value="Switzerland">Switzerland</option>
                        <option value="Syria">Syria</option>
                        <option value="Tahiti">Tahiti</option>
                        <option value="Taiwan">Taiwan</option>
                        <option value="Tajikistan">Tajikistan</option>
                        <option value="Tanzania">Tanzania</option>
                        <option value="Thailand">Thailand</option>
                        <option value="Togo">Togo</option>
                        <option value="Tokelau">Tokelau</option>
                        <option value="Tonga">Tonga</option>
                        <option value="Trinidad & Tobago">
                          Trinidad & Tobago
                        </option>
                        <option value="Tunisia">Tunisia</option>
                        <option value="Turkey">Turkey</option>
                        <option value="Turkmenistan">Turkmenistan</option>
                        <option value="Turks & Caicos Is">
                          Turks & Caicos Is
                        </option>
                        <option value="Tuvalu">Tuvalu</option>
                        <option value="Uganda">Uganda</option>
                        <option value="United Kingdom">United Kingdom</option>
                        <option value="Ukraine">Ukraine</option>
                        <option value="United Arab Erimates">
                          United Arab Emirates
                        </option>
                        <option value="United States of America">
                          United States of America
                        </option>
                        <option value="Uraguay">Uruguay</option>
                        <option value="Uzbekistan">Uzbekistan</option>
                        <option value="Vanuatu">Vanuatu</option>
                        <option value="Vatican City State">
                          Vatican City State
                        </option>
                        <option value="Venezuela">Venezuela</option>
                        <option value="Vietnam">Vietnam</option>
                        <option value="Virgin Islands (Brit)">
                          Virgin Islands (Brit)
                        </option>
                        <option value="Virgin Islands (USA)">
                          Virgin Islands (USA)
                        </option>
                        <option value="Wake Island">Wake Island</option>
                        <option value="Wallis & Futana Is">
                          Wallis & Futana Is
                        </option>
                        <option value="Yemen">Yemen</option>
                        <option value="Zaire">Zaire</option>
                        <option value="Zambia">Zambia</option>
                        <option value="Zimbabwe">Zimbabwe</option>
                      </select>


                      <div className="mb-4">
                        <h3 className="mb-2">
                          {intl.locale === "en" ? `State ` : `Bundesland `}*
                        </h3>
                        <input
                          type="text"
                          name="state"
                          className="form-control"
                          value={value["state"] || ``}
                          onChange={handleChange}
                        />

                        {serverResponse.indexOf("null_state") >= 0
                          && <div className="form-alert-box"><span>{intl.locale === "en" ? `Fill out this field` : `Bitte füllen Sie das Feld aus.`}</span></div>
                        }
                      </div>

                      <div className="flex-row mx-n2">
                        <div className="flex-column-4 px-2">
                          <h3 className="mb-2">
                            {intl.locale === "en" ? `Zip Code` : `Postleitzahl`}
                          </h3>
                          <input
                            type="text"
                            name="zip"
                            className="form-control mb-4"
                            value={value["zip"] || ``}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="flex-column-8 px-2">
                          <h3 className="mb-2">
                            {intl.locale === "en" ? `City` : `Stadt`}
                          </h3>
                          <input
                            type="text"
                            name="city"
                            className="form-control mb-2"
                            value={value["city"] || ``}
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div className="mb-4">
                        <h3 className="mb-2">
                          {intl.locale === "en"
                            ? `Which best describes you? `
                            : `Was trifft auf Sie zu? `}
                          *
                        </h3>
                        <div>
                          <input
                            type="radio"
                            name="describes"
                            value="3"
                            className="form-control-radio mb-2"
                            onChange={handleChange}
                          />
                          {intl.locale === "en"
                            ? `Patients / Caregivers`
                            : `Patient/Betreuer`}
                        </div>

                        <div>
                          <input
                            type="radio"
                            name="describes"
                            value="1"
                            className="form-control-radio mb-2"
                            onChange={handleChange}
                          />
                          {intl.locale === "en" ? `Clinicians` : `Mediziner`}
                        </div>
                        <div>
                          <input
                            type="radio"
                            name="describes"
                            value="2"
                            className="form-control-radio mb-2"
                            onChange={handleChange}
                          />
                          {intl.locale === "en"
                            ? `Medical device Professionals`
                            : `Spezialist für Medizinprodukte`}
                        </div>
                        {serverResponse.indexOf("null_describes") >= 0
                          && <div className="form-alert-box"><span>{intl.locale === "en" ? `Fill out this field` : `Bitte füllen Sie das Feld aus.`}</span></div>
                        }
                      </div>

                      <div className="form-group form-check mb-3">
                        <input
                          id="privacy-check"
                          type="checkbox"
                          name="privacy"
                          value="1"
                          className="form-check-input"
                          onChange={handleChange}
                        />
                        <label className="form-check-label" htmlFor="privacy-check">
                          {intl.locale === "en"
                            ? `I would like to be informed about news.
                        I hereby consent to the processing of my contact and inquiry data entered here, as well as any health data contained therein, for the purpose of sending mailings and newsletters from Cyberdyne Care Robotics GmbH regarding news in the field of our services and medical and non-medical products. I may withdraw my consent at any time with effect for the future by sending an e-mail to bochum@ccr-deutschland.de.`
                            : `Ich will über Neuigkeiten informiert werden. Ich bin mit der Verarbeitung meiner Kontakt- und hier eingegebener Anfragedaten, sowie darin enthaltener persönlicher Gesundheitsdaten, einverstanden. Ich erlaube die Verarbeitung zum Zweck der Kontaktierung mittels E-Mail, sowie dem Versenden von Newslettern über die Dienste, medizinische und nicht-medizinische Produkte der Cyberdyne Care Robotics GmbH. Ich kann mein Einverständnis jederzeit widerrufen, in dem ich eine E-Mail an bochum@ccr-deutschland.de sende.`}
                        </label>
                      </div>
                      {intl.locale === "en" ? (
                        <p>
                          Please read our
                          <a
                            className="link-simple-slim ml-1"
                            href="../privacy/"
                            target="_blank"
                          >
                            privacy policy
                          </a>
                          .
                        </p>
                      ) : (
                        <p>
                          Bitte beachten Sie unsere
                          <a
                            className="link-simple-slim ml-1"
                            href="../privacy/"
                            target="_blank"
                          >
                            Datenschutzhinweise
                          </a>
                          .
                        </p>
                      )}

                      {serverResponse.indexOf("null_privacy") >= 0
                        && <div className="form-alert-box"><span>{intl.locale === "en" ? `Fill out this field` : `Bitte füllen Sie das Feld aus.`}</span></div>
                      }
                    </div>
                  </div>
                  <input
                    type="submit"
                    value={intl.locale === "en" ? `Send` : `Absenden`}
                    name="submit"
                    className="link-button"
                  />
                </form>
                </GoogleReCaptchaProvider>
                {serverResponse
                  && serverResponse.indexOf("fields_null") < 0
                    && <div style={{ border: "solid 3px #eee", padding: "20px", marginBottom: "10px"}}>
                      <span style={{fontWeight: "700"}}>{serverResponse}</span>
                      {/*
                      {JSON.stringify(serverResponse)}
                      <hr />
                      {JSON.stringify(serverResponse[0]["response"]).match(/250 2.0.0 OK/) ? "av" : "bv"}
                    */}
                     </div>
                }
                {/*
                <div style={{ border: "solid 6px #eee", padding: "20px" }}>
                  <h2 style={{ fontSize: "0.95rem" }}>
                    開発用: サーバーレスポンス
                  </h2>
                  <p
                    style={{
                      fontSize: "0.8rem",
                      border: "solid 6px #eee",
                      padding: "20px",
                    }}
                  >
                    {JSON.stringify(serverResponse)}
                  </p>
                  <h2 style={{ fontSize: "0.95rem" }}>開発用: value</h2>
                  <p
                    style={{
                      fontSize: "0.8rem",
                      border: "solid 6px #eee",
                      padding: "20px",
                    }}
                  >
                    {value && JSON.stringify(value)}
                  </p>
                </div>
                */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default PageContact;
